import React, { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CartComponent from "../../../../shop/components/cart/CartComponent";
import Footer from "../../../../shop/components/layout/footer/Footer";
import Navbar from "../../../../shop/components/navigation/Navbar";
import { isTokenExpired } from "../../../../utils/auth";
import "./DefaultLayout.css";
import { UserContext } from "../../../../utils/UserContext";
import "../../../../App.css";
import { Toaster } from "../../../../shad-components/ui/sonner";

export function formatPrice(priceString: string): string {
  // Ersetzen Sie alle vorhandenen Kommas durch Punkte und wandeln Sie den String in eine Zahl um
  let price = parseFloat(priceString.replace(",", "."));

  // Formatieren Sie den Preis mit zwei Dezimalstellen
  let formattedPrice = price.toFixed(2);

  // Ersetzen Sie den Punkt durch ein Komma und fügen Sie Tausendertrennzeichen hinzu
  return formattedPrice.replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const DefaultLayout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const { fetchUser } = useContext(UserContext);
  useEffect(() => {
    if (location.pathname === "/") {
      return;
    }
    if (token && !isTokenExpired(token)) {
      fetchUser();
    }
    if (!token || isTokenExpired(token)) {
      localStorage.removeItem("userId");
      localStorage.removeItem("loggedInUser");
    }
  }, [fetchUser, location.pathname, navigate, token]);

  return (
    <div className="default-layout flex flex-col items-center justify-between">
      <Navbar />
      <CartComponent />
      <main className="mt-[65px] h-full w-full">
        <Outlet />
      </main>
      <Footer />
      <Toaster richColors closeButton />
    </div>
  );
};

export default DefaultLayout;
