// constants.ts
export const ROUTE_PATHS = {
  HOME: "/",
  PRODUCT: "/product/:title",
  SHOP: "/shop",
  WATCH: "/UHREN",
  JEWELRY: "/SCHMUCK",
  TS: "/THOMAS%20SABO",
  USER: "/user",
  PROFILE: "/user/profile",
  SETTINGS: "/user/settings",
  LAW: "/legal-information",
  IMPRINT: "/legal-information/imprint",
  DATA_SECURITY: "legal-information/datenschutz",
  AGB: "/legal-information/general-terms",
  ZAHLUNGVERSAND: "/payment&shipping",
  ABOUT: "/wer-wir-sind",
  BATTERY: "/battery",
  REVOCATION: "/revocation",
  CONTACT: "/contact",
  CHECKOUT: "/checkout",
  CHECKOUTPAY: "/checkout-bezahlung",
};

export const BREADCRUMBS = {
  HOME: {
    path: ROUTE_PATHS.HOME,
    label: "Home",
  },
  PRODUCT: {
    path: ROUTE_PATHS.PRODUCT,
    label: "Produkt",
  },
  SHOP: {
    path: ROUTE_PATHS.SHOP,
    label: "Shop",
  },
  WATCH: {
    path: ROUTE_PATHS.WATCH,
    label: "Uhren",
  },
  JEWELRY: {
    path: ROUTE_PATHS.JEWELRY,
    label: "Schmuck",
  },
  USER: {
    path: ROUTE_PATHS.USER,
    label: "Benutzer",
  },
  PROFILE: {
    path: ROUTE_PATHS.PROFILE,
    label: "Profil",
  },
  SETTINGS: {
    path: ROUTE_PATHS.SETTINGS,
    label: "Einstellungen",
  },
  LAW: {
    path: ROUTE_PATHS.LAW,
    label: "Rechtsinformationen",
  },
  IMPRINT: {
    path: ROUTE_PATHS.IMPRINT,
    label: "Impressum",
  },
  DATA_SECURITY: {
    path: ROUTE_PATHS.DATA_SECURITY,
    label: "Datenschutz & Sicherheit",
  },
  AGB: {
    path: ROUTE_PATHS.AGB,
    label: "AGB & Kundeninformationen",
  },
  TS: {
    path: ROUTE_PATHS.TS,
    label: "Thomas Sabo",
  },
  ZAHLUNGVERSAND: {
    path: ROUTE_PATHS.ZAHLUNGVERSAND,
    label: "Zahlung & Versand",
  },
  ABOUT: {
    path: ROUTE_PATHS.ABOUT,
    label: "Wer wir sind",
  },
  BATTERY: {
    path: ROUTE_PATHS.BATTERY,
    label: "Batterierücknahme",
  },
  REVATION: {
    path: ROUTE_PATHS.REVOCATION,
    label: "Wiederrufsbelehrung",
  },
  CONTACT: {
    path: ROUTE_PATHS.CONTACT,
    label: "Kontakt",
  },
  CHECKOUT: {
    path: ROUTE_PATHS.CHECKOUT,
    label: "Checkout",
  },
  CHECKOUTPAY: {
    path: ROUTE_PATHS.CHECKOUTPAY,
    label: "Bezahlung",
  },
};
