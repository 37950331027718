import React from "react";
import './SlickNavArrow.css'

interface ArrowProps {
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    // Weitere Props, die Ihre Pfeilkomponente benötigt
}


const CustomPrevArrow: React.FC<ArrowProps> = (props) => (
    <div className="custom-prev-arrow" onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
    }}>

        <svg width="32" height="32" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M45.4645 60.4645C43.5118 62.4171 43.5118 65.5829 45.4645 67.5355L77.2843 99.3553C79.2369 101.308 82.4027 101.308 84.3553 99.3553C86.308 97.4027 86.308 94.2369 84.3553 92.2843L56.0711 64L84.3553 35.7157C86.308 33.7631 86.308 30.5973 84.3553 28.6447C82.4027 26.692 79.2369 26.692 77.2843 28.6447L45.4645 60.4645ZM50 59H49V69H50V59Z" fill="#E8E8ED" />
        </svg>


    </div>
);

const CustomNextArrow: React.FC<ArrowProps> = (props) => (
    <div className="custom-next-arrow" onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
    }}>
        <svg width="32" height="32" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M82.5355 67.5355C84.4882 65.5829 84.4882 62.4171 82.5355 60.4645L50.7157 28.6447C48.7631 26.692 45.5973 26.692 43.6447 28.6447C41.692 30.5973 41.692 33.7631 43.6447 35.7157L71.9289 64L43.6447 92.2843C41.692 94.2369 41.692 97.4027 43.6447 99.3553C45.5973 101.308 48.7631 101.308 50.7157 99.3553L82.5355 67.5355ZM78 69H79V59H78V69Z" fill="#E8E8ED" />
        </svg>


    </div>
);

const CustomPrevArrowAlt: React.FC<ArrowProps> = (props) => (
    <div className="custom-prev-arrow-alt" onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
    }}>
        <svg width="32" height="32" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M45.4645 60.4645C43.5118 62.4171 43.5118 65.5829 45.4645 67.5355L77.2843 99.3553C79.2369 101.308 82.4027 101.308 84.3553 99.3553C86.308 97.4027 86.308 94.2369 84.3553 92.2843L56.0711 64L84.3553 35.7157C86.308 33.7631 86.308 30.5973 84.3553 28.6447C82.4027 26.692 79.2369 26.692 77.2843 28.6447L45.4645 60.4645ZM50 59H49V69H50V59Z" fill="#E8E8ED" />
        </svg>

    </div>
);

const CustomNextArrowAlt: React.FC<ArrowProps> = (props) => (
    <div className="custom-next-arrow-alt" onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
    }}>
        <svg width="32" height="32" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M82.5355 67.5355C84.4882 65.5829 84.4882 62.4171 82.5355 60.4645L50.7157 28.6447C48.7631 26.692 45.5973 26.692 43.6447 28.6447C41.692 30.5973 41.692 33.7631 43.6447 35.7157L71.9289 64L43.6447 92.2843C41.692 94.2369 41.692 97.4027 43.6447 99.3553C45.5973 101.308 48.7631 101.308 50.7157 99.3553L82.5355 67.5355ZM78 69H79V59H78V69Z" fill="#E8E8ED" />
        </svg>

    </div>
);

const CustomPrevArrowAltRel: React.FC<ArrowProps> = (props) => (
    <div className="w-15 h-15 p-2 bg-gray-600 rounded-full flex items-center justify-center" onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
    }}>
        <svg width="25" height="25" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M45.4645 60.4645C43.5118 62.4171 43.5118 65.5829 45.4645 67.5355L77.2843 99.3553C79.2369 101.308 82.4027 101.308 84.3553 99.3553C86.308 97.4027 86.308 94.2369 84.3553 92.2843L56.0711 64L84.3553 35.7157C86.308 33.7631 86.308 30.5973 84.3553 28.6447C82.4027 26.692 79.2369 26.692 77.2843 28.6447L45.4645 60.4645ZM50 59H49V69H50V59Z" fill="#E8E8ED" />
        </svg>

    </div>
);

const CustomNextArrowAltRel: React.FC<ArrowProps> = (props) => (
    <div className="w-15 h-15 p-2 bg-gray-600 rounded-full items-center justify-center flex" onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
    }}>
        <svg width="25" height="25" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M82.5355 67.5355C84.4882 65.5829 84.4882 62.4171 82.5355 60.4645L50.7157 28.6447C48.7631 26.692 45.5973 26.692 43.6447 28.6447C41.692 30.5973 41.692 33.7631 43.6447 35.7157L71.9289 64L43.6447 92.2843C41.692 94.2369 41.692 97.4027 43.6447 99.3553C45.5973 101.308 48.7631 101.308 50.7157 99.3553L82.5355 67.5355ZM78 69H79V59H78V69Z" fill="#E8E8ED" />
        </svg>
    </div>
);

export { CustomPrevArrow, CustomNextArrow, CustomNextArrowAlt, CustomPrevArrowAlt, CustomPrevArrowAltRel, CustomNextArrowAltRel };
