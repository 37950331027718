import { ReactNode } from 'react'

// Definieren des Typs für die Props der Komponente
interface NavDropdownProps {
    children: ReactNode;
    closeFilter: () => void;
    isFilterOpen: boolean;
}

// Anpassen der Funktionskomponentendefinition, um die Props zu akzeptieren
export default function NavDropdown({ children, isFilterOpen, closeFilter }: NavDropdownProps) {

    const cartClass = isFilterOpen ? "cart-modal open min-h-screen overflow-scroll" : "cart-modal";
    const backdropClass = isFilterOpen ? "modal-backdrop open" : "modal-backdrop";

    return (
        <div className={backdropClass} onClick={closeFilter}>
            <div className={cartClass} onClick={(e) => e.stopPropagation()}>
                <div className='flex items-center justify-between'>
                    <button onClick={closeFilter} className='border rounded-lg border-gray-600 px-2 py-1 hover:bg-gray-600 hover:text-white'>&#10006;</button>
                </div>
                <h2 className='font-bold text-3xl text-gray-900 mt-6'>Filter</h2>
                <div className="mt-6">
                    {children}
                </div>
                <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                </div>
            </div>
        </div>
    )
}
