import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './EmailVerificationPage.css';

const EmailVerificationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState('');

  const getTokenFromSearchParams = () => {
    return new URLSearchParams(location.search).get('token');
  };

  useEffect(() => {
    const verifyEmail = async () => {
      const token = getTokenFromSearchParams();
      if (token) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/verify-email?token=${token}`);
          const data = await response.json();

          if (response.ok) {
            setVerificationStatus('Ihre E-Mail wurde erfolgreich verifiziert. Sie werden in Kürze weitergeleitet.');
            setTimeout(() => navigate('/sign-in'), 3000);
          } else {
            setVerificationStatus(data.message || 'Fehler bei der Verifizierung.');
          }

        } catch (error) {
          setVerificationStatus('Fehler beim Verbinden zum Server.');
        }
      }
    };

    verifyEmail();
  }, [navigate, location]);

  return (
    <>
      <div className="verification-container">
        <h1>E-Mail-Verifizierung</h1>
        <p>{verificationStatus}</p>
      </div>
    </>
  );
};

export default EmailVerificationPage;
