import React, { useContext, useState } from "react";

import "./DeliveryAdressModal.css";
import { UserContext } from "../../../utils/UserContext";
import Select from "react-select";

const optionsSalutation = [
  { value: "Herr", label: "Herr" },
  { value: "Frau", label: "Frau" },
  { value: "", label: "Keine Angabe" },
];

function Modal() {
  const { user, editUser } = useContext(UserContext);
  const [newFirstName, setNewFirstName] = useState(user?.firstName || "");
  const [newLastName, setNewLastName] = useState(user?.lastName || "");
  const [newStreetAdressName, setNewStreetAdressName] = useState(
    user?.streetAddress || "",
  );
  const [newPostal, setNewPostal] = useState(user?.postalCode || "");
  const [newCountry, setNewCountry] = useState(user?.country || "");
  const [newCity, setNewCity] = useState(user?.city || "");
  const [newSalutation, setNewSalutation] = useState(user?.salutation || "");
  const [newExtra, setNewExtra] = useState(user?.adressExtra || "");
  const handleSelectSalutationChange = (
    newValue: { value: string; label: string } | string | null,
    actionMeta: { action: string },
  ) => {
    if (newValue && typeof newValue === "object") {
      setNewSalutation(newValue.value);
    } else {
      setNewSalutation("");
    }
  };
  function SaveIconSVG() {
    return (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.49991 0.877045C3.84222 0.877045 0.877075 3.84219 0.877075 7.49988C0.877075 11.1575 3.84222 14.1227 7.49991 14.1227C11.1576 14.1227 14.1227 11.1575 14.1227 7.49988C14.1227 3.84219 11.1576 0.877045 7.49991 0.877045ZM1.82708 7.49988C1.82708 4.36686 4.36689 1.82704 7.49991 1.82704C10.6329 1.82704 13.1727 4.36686 13.1727 7.49988C13.1727 10.6329 10.6329 13.1727 7.49991 13.1727C4.36689 13.1727 1.82708 10.6329 1.82708 7.49988ZM10.1589 5.53774C10.3178 5.31191 10.2636 5.00001 10.0378 4.84109C9.81194 4.68217 9.50004 4.73642 9.34112 4.96225L6.51977 8.97154L5.35681 7.78706C5.16334 7.59002 4.84677 7.58711 4.64973 7.78058C4.45268 7.97404 4.44978 8.29061 4.64325 8.48765L6.22658 10.1003C6.33054 10.2062 6.47617 10.2604 6.62407 10.2483C6.77197 10.2363 6.90686 10.1591 6.99226 10.0377L10.1589 5.53774Z"
          fill="currentColor"
          fillRule="evenodd"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  }
  return (
    <>
      <label className="btn" htmlFor="modal-p">
        Persönliche Angaben bearbeiten
      </label>

      <input className="modal-state" id="modal-p" type="checkbox" />
      <div className="modal">
        <label className="modal__bg" htmlFor="modal-p"></label>
        <div className="modal__inner">
          <div>
            <label className="modal__close" htmlFor="modal-p"></label>
            <h2 className="text-xl text-gray-500">Aktuelle Angaben</h2>
            <p className="text-xl">{user?.salutation}</p>
            <p className="text-xl">
              {user?.firstName}&nbsp;{user?.lastName}
            </p>
            <p className="text-xl">{user?.email}</p>
          </div>
          <div className="flex h-full w-full items-center justify-center">
            <form
              onSubmit={() => {
                editUser(
                  newSalutation,
                  newFirstName,
                  newLastName,
                  newStreetAdressName,
                  newExtra,
                  newPostal,
                  newCity,
                  newCountry,
                );
              }}
              method="put"
              className="flex flex-col items-start justify-start gap-4"
            >
              <div className="flex w-full items-start justify-start gap-4">
                <span className="flex w-full flex-col items-start justify-start">
                  <label htmlFor="personFirst">Anrede:</label>
                  <Select
                    options={optionsSalutation}
                    placeholder="Keine Angabe"
                    value={
                      newSalutation
                        ? optionsSalutation.find(
                            (option) => option.value === newSalutation,
                          )
                        : ""
                    }
                    className="w-full"
                    onChange={handleSelectSalutationChange}
                  />
                </span>
                <span className="flex w-full flex-col items-start justify-start">
                  <label htmlFor="personFirst">Vorname:</label>
                  <input
                    type="text"
                    id="personFirst"
                    placeholder="Max"
                    className="w-full rounded-lg border-[1px] border-gray-500 px-4 py-2"
                    onChange={(e) => setNewFirstName(e.target.value)}
                    required
                  />
                </span>
                <span className="flex w-full flex-col items-start justify-start">
                  <label htmlFor="personLast">Nachname:</label>
                  <input
                    type="text"
                    id="personLast"
                    placeholder="Mustermann"
                    className="w-full rounded-lg border-[1px] border-gray-500 px-4 py-2"
                    onChange={(e) => setNewLastName(e.target.value)}
                    required
                  />
                </span>
              </div>
              <button
                type="submit"
                className="flex items-center justify-center gap-3 rounded-md bg-blue-800 px-4 py-2 text-gray-50"
              >
                <SaveIconSVG />
                Daten speichern
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
