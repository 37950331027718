import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Cart, CartItem, getInitialCart } from "../shop/models/Cart";
import { CartDB, CartDBItem, getInitialCartDB } from "../shop/models/CartDB";
import { formatNumberGermanStyle } from "../App";
import { useNavigate } from "react-router-dom";

interface CartContextType {
  cart: Cart;
  cartDB: CartDB;
  addToCart: (item: CartItem) => void;
  removeFromCart: (productId: number) => void;
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  isCartOpen: boolean;
  openCart: () => void;
  closeCart: () => void;
  calculateTotalPrice: () => number;
  calculateTotalPriceDB: () => number;
  addOneToCart: (productId: number) => void;
  removeOneFromCart: (productId: number) => void;
  addToDatabaseCart: (item: CartDBItem) => void;
  removeFromDatabaseCart: (itemId: number) => void;
  modifyIncrementCart: (productId: number) => void;
  modifyDecrementCart: (productId: number) => void;
}

export const CartContext = createContext<CartContextType>(
  {} as CartContextType,
);

export const CartProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [cart, setCart] = useState<Cart>(getInitialCart());
  const [cartDB, setCartDB] = useState<CartDB>(getInitialCartDB());
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCartOpen, setIsCartOpen] = useState<boolean>(false);
  const loggedInUser = localStorage.getItem("loggedInUser");
  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const openCart = useCallback(() => setIsCartOpen(true), []);
  const closeCart = useCallback(() => setIsCartOpen(false), []);

  const saveCartToLocalStorage = useCallback((cart: Cart) => {
    try {
      localStorage.setItem("cart", JSON.stringify(cart));
    } catch (error) {
      console.error("Fehler beim Speichern des Warenkorbs:", error);
    }
  }, []);

  const fetchWithAuth = useCallback(
    async (url: string, method: string, body?: object) => {
      try {
        const response = await fetch(url, {
          method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(body),
        });
        if (response.status === 401) {
          localStorage.clear();
          navigate("/sign-in");
        }
        return await response.json();
      } catch (error) {
        console.error("Fehler bei der Anfrage:", error);
        throw error;
      }
    },
    [authToken, navigate],
  );

  const addToCart = useCallback(
    (newItem: CartItem) => {
      setCart((prevCart) => {
        const existingItem = prevCart.items.find(
          (item) => item.product.id === newItem.product.id,
        );
        if (existingItem) {
          return {
            items: prevCart.items.map((item) =>
              item.product.id === newItem.product.id
                ? { ...item, quantity: item.quantity + newItem.quantity }
                : item,
            ),
          };
        } else {
          return {
            items: [...prevCart.items, newItem],
          };
        }
      });
      openCart();
      openModal();
    },
    [openCart, openModal],
  );

  const addToDatabaseCart = useCallback(
    async (newItem: CartDBItem) => {
      const updatedCart = await fetchWithAuth("/api/cart/modify", "POST", {
        productId: newItem.id,
        quantity: newItem.quantity,
      });
      setCartDB(updatedCart);
      openCart();
      openModal();
    },
    [fetchWithAuth, openCart, openModal],
  );

  const modifyCart = useCallback(
    async (productId: number, quantity: number) => {
      const updatedCart = await fetchWithAuth("/api/cart/modify", "POST", {
        productId,
        quantity,
      });
      setCartDB(updatedCart);
    },
    [fetchWithAuth],
  );

  const modifyIncrementCart = useCallback(
    (productId: number) => {
      modifyCart(productId, 1);
    },
    [modifyCart],
  );

  const modifyDecrementCart = useCallback(
    (productId: number) => {
      modifyCart(productId, -1);
    },
    [modifyCart],
  );

  const removeFromDatabaseCart = useCallback(
    async (productId: number) => {
      const updatedCart = await fetchWithAuth("/api/cart/remove", "POST", {
        productId,
      });
      setCartDB(updatedCart);
    },
    [fetchWithAuth],
  );

  const removeFromCart = useCallback(
    (productId: number) => {
      setCart((prevCart) => {
        const updatedItems = prevCart.items.filter(
          (item) => item.product.id !== productId,
        );
        const updatedCart = { ...prevCart, items: updatedItems };
        saveCartToLocalStorage(updatedCart);
        return updatedCart;
      });
    },
    [saveCartToLocalStorage],
  );

  const addOneToCart = useCallback((productId: number) => {
    setCart((prevCart) => {
      const existingItem = prevCart.items.find(
        (item) => item.product.id === productId,
      );
      if (existingItem) {
        return {
          items: prevCart.items.map((item) =>
            item.product.id === productId
              ? { ...item, quantity: item.quantity + 1 }
              : item,
          ),
        };
      } else {
        return prevCart;
      }
    });
  }, []);

  const removeOneFromCart = useCallback((productId: number) => {
    setCart((prevCart) => {
      const existingItem = prevCart.items.find(
        (item) => item.product.id === productId,
      );
      if (existingItem && existingItem.quantity > 1) {
        return {
          items: prevCart.items.map((item) =>
            item.product.id === productId
              ? { ...item, quantity: item.quantity - 1 }
              : item,
          ),
        };
      } else {
        return {
          items: prevCart.items.filter((item) => item.product.id !== productId),
        };
      }
    });
  }, []);

  const calculateTotalPrice = useCallback(() => {
    return cart.items.reduce((total, item) => {
      const price = item.product.price || 0;
      return total + price * item.quantity;
    }, 0);
  }, [cart]);

  const calculateTotalPriceDB = useCallback(() => {
    return cartDB.items.reduce((total, item) => {
      const price = item.product.price || 0;
      formatNumberGermanStyle(total);
      return total + price * item.quantity;
    }, 0);
  }, [cartDB]);

  const addToDatabaseCartFromLocal = useCallback(async () => {
    try {
      for (const item of cart.items) {
        await fetchWithAuth("/api/cart/modify", "POST", {
          productId: item.product.id,
          quantity: item.quantity,
        });
      }

      const updatedCart = await fetchWithAuth("/api/cart/cart", "GET");
      setCartDB(updatedCart);
      localStorage.setItem("cart", JSON.stringify({ items: [] }));
    } catch (error) {
      console.error("Fehler beim Hinzufügen zum Warenkorb:", error);
    }
  }, [cart, fetchWithAuth]);

  useEffect(() => {
    if (loggedInUser) {
      addToDatabaseCartFromLocal();
    } else {
      saveCartToLocalStorage(cart);
    }
  }, [cart, loggedInUser, addToDatabaseCartFromLocal, saveCartToLocalStorage]);

  return (
    <CartContext.Provider
      value={{
        cart,
        cartDB,
        addToCart,
        removeFromCart,
        isModalOpen,
        openModal,
        closeModal,
        isCartOpen,
        openCart,
        closeCart,
        calculateTotalPrice,
        removeOneFromCart,
        addOneToCart,
        addToDatabaseCart,
        removeFromDatabaseCart,
        modifyIncrementCart,
        modifyDecrementCart,
        calculateTotalPriceDB,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
