import { FunctionComponent } from 'react';
import earlyChronik from "../../assets/images/IMG_3728.jpg"
import midChronik from "../../assets/images/1710325450078.jpg"
import lateChronik from "../../assets/images/IMG_3703.jpg"

interface AboutPageProps {
    // Definiere die Prop-Typen hier
}

const AboutPage: FunctionComponent<AboutPageProps> = () => {

    return (
        <>
            <div className='flex flex-col items-center justify-center w-full lg:w-1/2 mx-auto my-[85px] gap-8'>
                <div className='text-left font-GeistBold text-5xl py-6'>
                    <h1>Familientradition seit 1932</h1>
                </div>
                <div className='relative flex items-center justify-center gap-8 text-lg'>
                    <img src={earlyChronik} className='w-1/2 object-cover' alt="" />
                    <p className='text-center max-w-1/2'>
                        Gegründet wurde Juwelier Bucher im Jahr 1932 durch den Uhrmachermeister Fritz Bucher. Das Geschäft befand sich in der Blücherstraße 66 in Reichenbach - die ersten Kunden wurden noch im Wohnzimmer der Eltern empfangen.
                        Sein Sohn Christian Bucher, ebenfalls Uhrmachermeister, übernahm 1968 das Unternehmen, welches er zusammen mit seiner Frau Waltraud führte.
                    </p>
                </div>
                <div className='flex flex-row-reverse items-center justify-center gap-8 text-lg'>
                    <img src={midChronik} className='w-1/2 object-cover' alt="" />
                    <p className='text-center max-w-1/2'>
                        1990 wurde die erste Modernisierung/ Umbau des Reichenbacher Geschäftes ausgeführt, im Jahr 1996 folgte die Erweiterung des Reichenbacher Stammgeschäftes. Mit der Gründung der "Juwelier Bucher GbR" am 01.06.2000 übernahmen die Brüder Frank und Holger Bucher das väterliche Geschäft. Am 02.10.2001 expandierte Juwelier Bucher mit einer Filiale in die Stadtgalerie Plauen.
                    </p>
                </div>
                <div className='relative flex items-center justify-center gap-8 text-lg'>
                    <img src={lateChronik} className='w-1/2 object-cover' alt="" />
                    <p className='text-center max-w-1/2'>
                        Mit der Tradition einer fast 90-jährigen Firmengeschichte und dem Service eines modernen Unternehmens, bieten wir Ihnen in angenehmer Atmosphäre freundliche und kompetente Beratung. Wir sind stets auf der Suche nach inspirierenden Ideen, um das Unternehmen mit viel Energie in die Zukunft zu begleiten.
                    </p>
                </div>
            </div>
        </>
    );
}

export default AboutPage;