import React from "react";
import { Link, useLocation } from "react-router-dom";
import { BREADCRUMBS } from "../../../utils/constants";
import "./BreadCrumbs.css";

const ArrowSVG = () => (
  <svg
    className="mx-4"
    width="14"
    height="14"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 4l8 8-8 8"
      stroke="#fff"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

interface BreadcrumbLinkProps {
  to: string;
  label: string;
}

const BreadcrumbLink: React.FC<BreadcrumbLinkProps> = ({ to, label }) => (
  <div className="flex items-center justify-center">
    <Link to={to}>{label}</Link>
    <ArrowSVG />
  </div>
);

const useBreadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  // Fügen Sie das Startseiten-Breadcrumb hinzu, wenn die Route nicht nur '/' ist
  const breadcrumbs =
    location.pathname !== "/"
      ? [
          { routeTo: "/", breadcrumbLabel: "Startseite", isLast: false },
          ...pathnames.map((value, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;
            const matchingBreadcrumb = Object.values(BREADCRUMBS).find((bc) =>
              bc.path.includes(value),
            );
            const breadcrumbLabel = matchingBreadcrumb
              ? matchingBreadcrumb.label
              : value;

            return { routeTo, breadcrumbLabel, isLast };
          }),
        ]
      : [];

  return breadcrumbs;
};

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs();

  if (breadcrumbs.length === 0) {
    return null;
  }
  return (
    <div className="flex h-8 w-full items-center justify-start bg-slate-800 px-4 text-gray-50 lg:px-0">
      <div className="mx-auto flex w-full max-w-[1560px] items-start justify-start">
        {breadcrumbs.map(({ routeTo, breadcrumbLabel, isLast }, index) =>
          isLast ? (
            <span
              key={index}
              className="flex items-center justify-center font-bold text-blue-300"
            >
              {breadcrumbLabel}
            </span>
          ) : (
            <BreadcrumbLink key={index} to={routeTo} label={breadcrumbLabel} />
          ),
        )}
      </div>
    </div>
  );
};

export default Breadcrumbs;
