import { PropsWithChildren, createContext, useState } from "react";
import { User } from "../shop/models/User";
import { useNavigate } from "react-router-dom";

interface UserContextType {
  user?: User;
  fetchUser: () => void;
  editUser: (
    salutation: string,
    firstName: string,
    lastName: string,
    streetAddress: string,
    addressExtra: string,
    postalCode: string,
    city: string,
    country: string,
  ) => Promise<void>;
}

export const UserContext = createContext<UserContextType>(
  {} as UserContextType,
);

export const UserProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [user, setUser] = useState<User>();
  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();

  async function fetchUser() {
    try {
      const response = await fetch("/api/user/get-user", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUser(data);
      }

      if (response.status === 401) {
        navigate("/sign-in");
      }
    } catch (error) {}
  }

  async function editUser(
    salutation: string,
    firstName: string,
    lastName: string,
    streetAddress: string,
    addressExtra: string,
    postalCode: string,
    city: string,
    country: string,
  ) {
    try {
      const response = await fetch("/api/user/edit-user", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          salutation,
          firstName,
          lastName,
          streetAddress,
          addressExtra,
          postalCode,
          city,
          country,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        setUser(data);
        alert("Nutzer erfolgreich geändert!");
      }
      if (!response.ok) {
        const errorData = await response.json();
        alert(`Fehler: ${errorData.message}`);
        return;
      }
      if (response.status === 404) {
        navigate("/sign-in");
        localStorage.clear();
      }
    } catch (error) {}
  }

  return (
    <UserContext.Provider value={{ user, fetchUser, editUser }}>
      {children}
    </UserContext.Provider>
  );
};
