import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import backgroundLogin from "../../assets/images/bg/Union_D016.407.16.XXX.00_mood5.jpg";
import jbLogo from "../../assets/icons/juwelier-bucher-dark.svg";
import { UserContext } from "../../../utils/UserContext";

function LoginPage() {
  const { user } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggedInUser, setLoggedInUser] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  //const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  /* const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }; */

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json(); // Verschieben der Datenextraktion vor die Statusprüfung
      if (response.status === 200) {
        const token = data.token;
        localStorage.setItem("authToken", token);
        localStorage.setItem("loggedInUser", email);
        localStorage.setItem("userId", data.userId);
        setLoggedInUser(email);
        setLoggedInUser(data.userId);
        setError(null);
        navigate("/");
      }
      if (response.status === 401) {
        setError("Die eingegebenen Anmeldedaten scheinen nicht zu stimmen.");
      }
    } catch (error) {
      console.error("Login failed:", error);
      setError("Beim Anmelden ist ein Fehler aufgetreten.");
      setEmail("");
      setPassword("");
    }
  };

  useEffect(() => {
    if (error) {
      emailInputRef.current?.focus();
      //passwordInputRef.current?.focus();
    }
  }, [error]);

  return (
    <div className="flex min-h-screen w-full items-center justify-center lg:h-screen lg:w-screen">
      <div className="relative flex min-h-screen w-full items-center justify-center bg-gray-50 xl:h-full xl:w-5/12">
        <Link to={"/"}>
          <img src={jbLogo} className="absolute left-4 top-4 w-44" alt="" />
        </Link>
        <div className="flex w-[90%] items-center justify-center xl:w-[400px]">
          <div className="flex w-full flex-col items-start justify-start">
            <h1 className="font-GeistBold pb-2 text-left text-3xl text-[#121212]">
              Willkommen
            </h1>
            <p className="font-Geist pb-8 text-left text-lg text-[#121212]">
              Melden Sie sich an.
            </p>
            <form
              className="flex w-full flex-col items-center justify-center gap-4"
              action="#"
            >
              <div className="flex w-full flex-col items-start justify-start">
                <label htmlFor="name" className="mb-2">
                  Benutzername
                </label>
                <input
                  className="loginInput w-full placeholder:text-sm"
                  ref={emailInputRef}
                  id="name"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Muster@Mail.de"
                />
              </div>
              <div className="flex w-full flex-col items-start justify-start">
                <span className="flex w-full items-center justify-between">
                  <label htmlFor="password" className="mb-2">
                    Passwort
                  </label>
                  <Link
                    to={"/forgot-email"}
                    className="text-sidebarGray-500 ml-auto hover:underline"
                  >
                    Kennwort vergessen?
                  </Link>
                </span>
                <input
                  ref={passwordInputRef}
                  className="loginInput w-full"
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                />
                {/* <button type="button" className='sh__icon' onClick={togglePasswordVisibility}>
                                {showPassword ? <p>&#10006;</p>
                                    : <p>&#128065;</p>}
                            </button> */}
              </div>
              <div className="">
                {/* <div className='keep-c'>
                                <input type="checkbox" name="keepLogged" id="keep" />
                                <label htmlFor="keep">Angemeldet bleiben?</label>
                            </div> */}
              </div>
              {error && (
                <p className="relative flex w-full items-center justify-end rounded-lg bg-red-200 py-2 text-red-500">
                  <span className="absolute left-0 top-0 flex h-full w-2 items-center justify-center rounded-bl-lg rounded-tl-lg bg-red-700 text-red-300"></span>
                  <span className="flex w-[calc(100%-14px)] items-center justify-center pr-[8px] text-sm text-red-700">
                    {error}
                  </span>
                </p>
              )}
              <button
                type="submit"
                className="w-full rounded-xl border-2 border-blue-500 bg-blue-900 px-4 py-2 text-gray-50 hover:bg-blue-800"
                onClick={handleSubmit}
              >
                Anmelden
              </button>
              <div className="mt-2 flex w-full items-center justify-center gap-2">
                <span className="m-0 p-0">Sie haben noch kein Konto?</span>
                <Link to={"/sign-up"} className="">
                  <span className="text-blue-600">Registrieren&#x2197;</span>
                </Link>
              </div>
            </form>
            {loggedInUser && <p>Erfolgreich angemeldet als: {email}</p>}
          </div>
        </div>
      </div>
      <img
        src={backgroundLogin}
        className="hidden h-screen object-cover xl:block xl:w-7/12"
        alt=""
      />
    </div>
  );
}

export default LoginPage;
