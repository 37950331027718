import { Component } from 'react';
import { Link } from 'react-router-dom';
import "./BucherLogo.css";

interface MyComponentProps { }

interface MyComponentState { }

class MyComponent extends Component<MyComponentProps, MyComponentState> {
	render() {
		return (
			<div>
				<Link to={'/'} className='center-logo'>
					<svg width="25" height="25" viewBox="0 0 46 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clipPath="url(#clip0_672_9)">
							<path d="M9.2 0.788086H36.8L46 13.7881H0L9.2 0.788086Z" stroke="white" />
							<path d="M0 13.7881H46L23 38.7881L0 13.7881Z" stroke="white" />
							<path d="M12 0.788086L7.50016 13.7881L23.0002 38.2881" stroke="white" />
							<path d="M33.5 0.788086L38.0001 13.7881L23.4921 37.8795" stroke="white" />
							<path d="M27 0.788086L28.9999 13.7887L23.4988 37.8235" stroke="white" />
							<path d="M23 38.2881L17.4999 13.788L19 0.788087" stroke="white" />
						</g>
						<defs>
							<clipPath id="clip0_672_9">
								<rect width="46" height="40" fill="white" />
							</clipPath>
						</defs>
					</svg>


				</Link>
			</div>
		);
	}
}

export default MyComponent;
