import { Product } from "../../utils/types";

export interface CartDBItem {
  id: number;
  product: Product;
  quantity: number;
}

export interface CartDB {
  items: CartDBItem[];
}

export const getInitialCartDB = (): CartDB => {
  return { items: [] };
};
