import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { formatPrice } from "../../../common/components/layouts/defaultLayout/DefaultLayout";
import { AEBOrder } from "../../../utils/types";

function CheckoutConfirmation() {
  const isLoggedIn = localStorage.getItem("loggedInUser");
  const location = useLocation();
  const [ordersList, setOrdersList] = useState<AEBOrder[]>([]);
  const authToken = localStorage.getItem("authToken");
  const [emptyOrder, setEmptyOrders] = useState("");

  function formatDate(dateInput: string | Date): string {
    const date =
      typeof dateInput === "string" ? new Date(dateInput) : dateInput;
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Intl.DateTimeFormat("de-DE", options).format(date);
  }

  async function fetchOrderHistory() {
    try {
      const response = await fetch("/api/user/get-order-history", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setOrdersList(data);
      }
      if (response.status === 404) {
        setEmptyOrders("Es wurden noch keine Bestellungen getätigt.");
        return;
      }
    } catch (error) {
      console.error("Fehler beim Laden der Bestellungen:", error);
    }
  }

  useEffect(() => {
    fetchOrderHistory();
  }, [isLoggedIn]);

  function truncateAdditionalInfo(text: string) {
    const maxLength = 100;
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + "...";
    } else {
      return text;
    }
  }

  const renderLocalCartItems = () =>
    ordersList.length === 0 ? (
      <div className="flex items-start justify-start p-8">
        <p className="mt-2">{emptyOrder}</p>
      </div>
    ) : (
      <div className="flex flex-col items-start justify-start">
        <ul className="bg-white px-8">
          {ordersList?.map((item) => (
            <div key={item.id} className="cartItem">
              {item.items.map((prod) => (
                <li
                  className="my-8 flex flex-row items-end justify-between"
                  key={prod.id}
                >
                  <div className="flex flex-row items-start justify-center gap-4">
                    <div className="h-52 w-52">
                      {prod.mainBaseImage ? (
                        <img
                          width={200}
                          height={200}
                          src={`data:image/jpeg;base64,${prod.mainBaseImage}`}
                          alt={prod.product.productName}
                          onError={(e) => {
                            console.error("Fehler beim Laden des Bildes:", e);
                            e.currentTarget.src = ""; // Fallback-Bild oder leer lassen
                          }}
                        />
                      ) : (
                        <div className="flex h-full w-full items-center justify-center rounded bg-gray-300 dark:bg-gray-700">
                          <svg
                            className="h-10 w-10 text-gray-200 dark:text-gray-600"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 18"
                          >
                            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                          </svg>
                        </div>
                      )}
                    </div>
                    <div className="flex min-h-[200px] flex-col justify-between">
                      <div className="flex h-full flex-col items-start justify-between">
                        <p className="flex max-w-[50ch] items-start justify-start text-xl font-medium">
                          <span>{prod.quantity}&nbsp;&#215;&nbsp;</span>
                          {prod.product.productName}
                        </p>
                        <p className="w-[70ch] text-lg font-medium text-gray-500">
                          {truncateAdditionalInfo(prod.product.additionalInfo)}
                        </p>
                      </div>
                      <p className="text-sm text-gray-500">
                        Bestellt&nbsp;am: {formatDate(item.createdAt)}
                      </p>
                    </div>
                  </div>
                  <p className="text-2xl font-bold">
                    {formatPrice(prod.price.toString())}&nbsp;€
                  </p>
                </li>
              ))}
              <hr className="my-6" />
            </div>
          ))}
        </ul>
      </div>
    );

  return (
    <div className="mx-auto mt-[2.5rem] flex flex-col items-center justify-center bg-gray-100">
      <section className="flex w-full flex-col items-start justify-center lg:flex-row">
        <section className="flex w-full flex-col items-start justify-center">
          <div className="w-full">
            <div className="flex items-center justify-center p-2 lg:p-12">
              <div className="mx-auto w-full max-w-[960px]">
                <h1 className="py-2 text-left text-3xl">Bestellung</h1>
                {isLoggedIn && renderLocalCartItems()}
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default CheckoutConfirmation;
