import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import backgroundLogin from "../../assets/images/bg/Union_D016.407.16.XXX.00_mood5.jpg";
import jbLogo from "../../assets/icons/juwelier-bucher-dark.svg";

function EnterMailPage() {
  const navigate = useNavigate();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState("");

  const SendResetPasswordMail = async () => {
    try {
      const response = await fetch(`/api/auth/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        navigate("/sign-in");
      } else {
        navigate("/sign-in");
      }
    } catch (error) {}
  };

  return (
    <div className="flex min-h-screen w-full items-center justify-center lg:h-screen lg:w-screen">
      <div className="relative flex min-h-screen w-full items-center justify-center bg-gray-50 xl:h-full xl:w-5/12">
        <Link to={"/"}>
          <img src={jbLogo} className="absolute left-4 top-4 w-44" alt="" />
        </Link>
        <div className="flex w-[90%] items-center justify-center xl:w-[400px]">
          <div className="flex w-full flex-col items-start justify-start">
            <h1 className="font-GeistBold pb-2 text-left text-3xl text-[#121212]">
              E-Mail zurücksetzen
            </h1>
            <p className="font-Geist pb-8 text-left text-lg text-[#121212]">
              Geben Sie bitte Ihre E-Mail ein. Anschließend erhalten Sie eine
              E-Mail in welcher Sie weitere Anweisungen erhalten.
            </p>
            <form
              className="flex w-full flex-col items-center justify-center gap-4"
              action="#"
            >
              <div className="flex w-full flex-col items-start justify-start">
                <label htmlFor="name" className="mb-2">
                  E-Mail
                </label>
                <input
                  className="loginInput w-full placeholder:text-sm"
                  ref={emailInputRef}
                  id="name"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Muster@Mail.de"
                />
              </div>
              <button
                type="submit"
                className="w-full rounded-xl border-2 border-blue-500 bg-blue-900 px-4 py-2 text-gray-50 hover:bg-blue-800"
                onClick={SendResetPasswordMail}
              >
                E-Mail senden
              </button>
            </form>
          </div>
        </div>
      </div>
      <img
        src={backgroundLogin}
        className="hidden h-screen object-cover xl:block xl:w-7/12"
        alt=""
      />
    </div>
  );
}

export default EnterMailPage;
