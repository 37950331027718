import { FunctionComponent } from 'react';

interface BatteryProps {
	// Definiere die Prop-Typen hier
}

const Battery: FunctionComponent<BatteryProps> = () => {
	// Implementiere deine Komponente hier

	return (
		<div className='flex flex-col items-start justify-start w-full lg:w-1/3 mx-auto mt-[85px] gap-2'>
			<div className='text-left font-GeistBold text-3xl'>
				<h1>Hinweise zur Batterierücknahme</h1>
			</div>
			<hr className='w-full h-[1px] bg-slate-300 mb-4' />
			<div className='flex flex-col items-start justify-center text-base'>
				<p className='py-6'>
					Im Zusammenhang mit dem Vertrieb von Batterien oder mit der Lieferung von Geräten, die Batterien enthalten, sind wir verpflichtet, Sie auf folgendes hinzuweisen:
					Sie sind zur Rückgabe gebrauchter Batterien als Endnutzer gesetzlich verpflichtet. Sie können Altbatterien, die wir als Neubatterien im Sortiment führen oder geführt haben, unentgeltlich an unserem Versandlager (Versandadresse) zurückgeben. Die auf den Batterien abgebildeten Symbole haben folgende Bedeutung:
				</p>
				<p>
					Das Symbol der durchgekreuzten Mülltonne bedeutet, dass die Batterie nicht in den Hausmüll gegeben werden darf.
					<br />Pb = Batterie enthält mehr als 0,004 Masseprozent Blei
					<br />Cd = Batterie enthält mehr als 0,002 Masseprozent Cadmium
					<br />Hg = Batterie enthält mehr als 0,0005 Masseprozent Quecksilber.
				</p>
			</div>
		</div>
	);
}

export default Battery;