import { Product } from "../../utils/types";

export interface CartItem {
  id: number;
  product: Product;
  quantity: number;
}
export interface Cart {
  items: CartItem[];
}
// Diese Funktion versucht, den Warenkorb aus dem Local Storage zu laden
export const getInitialCart = (): Cart => {
  const savedCart = localStorage.getItem("cart");
  return savedCart ? JSON.parse(savedCart) : { items: [] };
};
