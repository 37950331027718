import { FunctionComponent } from 'react';

import "./Imprint.css";

interface ImprintProps {
    // Definiere die Prop-Typen hier
}

const Imprint: FunctionComponent<ImprintProps> = () => {
    // Implementiere deine Komponente hier

    return (
        <div className='flex flex-col items-start justify-start w-full lg:w-1/3 mx-auto mt-[85px] gap-2'>
            <div className='text-left font-GeistBold text-3xl'>
                <h1>Impressum</h1>
            </div>
            <hr className='w-full h-[1px] bg-slate-300 mb-4' />
            <div className='ic__contentContainer'>
                <span className='text-gray-600 text-lg'>Gesetzliche Anbieterkennung:</span>
                <ul className='ic__contentContainer__list'>
                    <li className='ic__contentContainer__list__item'>
                        Juwelier Bucher GbR
                    </li>
                    <li className='ic__contentContainer__list__item'>
                        vertreten durch die Gesellschafter: Frank Bucher, Holger Bucher
                    </li>
                    <br />
                    <li className='ic__contentContainer__list__item'>
                        Weinholdstraße 33
                    </li>
                    <li className='ic__contentContainer__list__item'>
                        08468 Reichenbach
                    </li>
                    <li className='ic__contentContainer__list__item'>
                        Deutschland
                    </li>
                    <br />
                    <li className='ic__contentContainer__list__item'>
                        Telefon: 0 37 65/ 1 28 02
                    </li>
                    <li className='ic__contentContainer__list__item'>
                        Telefax: 0 37 65/ 71 97 27
                    </li>
                    <li className='ic__contentContainer__list__item'>
                        E-Mail: info@juwelier-bucher.de
                    </li>
                    <li className='ic__contentContainer__list__item'>
                        USt-IdNr.: DE 209306711
                    </li>
                </ul>
                <hr className='w-full h-[1px] bg-slate-300 mb-4' />
                <div className='ic__alt'>
                    <h3 className='text-gray-600 text-lg'>Alternative Striebeilegung</h3>
                    <p>Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar unter <a className='i__link' href="https://ec.europa.eu/odr">https://ec.europa.eu/odr</a>.</p>
                    <p>Wir sind bereit, an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen teilzunehmen.</p>
                </div>
                <br />
                <div className=''>
                    <h1 className='text-gray-600 text-lg'>FairCommerce</h1>
                    <p>Wir sind seit 23.07.2015 Mitglied der Initiative "FairCommerce".</p>
                    <p>Nähere Informationen hierzu finden Sie unter <a className='text-blue-400 underline' href="https://www.fair-commerce.de">www.fair-commerce.de</a></p>
                </div>
            </div>

        </div>
    );
}

export default Imprint;