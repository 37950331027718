import React from 'react';

function UserSettingsPage() {
  return (
    <>
      <div className="user-settings-container">
        {/* Hier wird die Produktliste gerendert */}
      </div>
    </>
  );
}

export default UserSettingsPage;
