import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function ResetPasswordPage() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { token } = useParams();

  const resetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("Die Passwörter stimmen nicht überein.");
      return;
    }

    try {
      const response = await fetch(`/api/auth/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, newPassword }),
      });

      if (response.ok) {
        alert("Ihr Passwort wurde erfolgreich zurückgesetzt.");
        navigate("/sign-in");
      } else {
        const data = await response.json();
        setError(data.message || "Ein Fehler ist aufgetreten.");
      }
    } catch (error) {
      setError("Netzwerkfehler beim Zurücksetzen des Passworts.");
    }
  };

  return (
    <div className="mx-auto flex w-1/2 flex-col items-center justify-center">
      <h1 className="text-3xl font-semibold">Passwort zurücksetzen</h1>
      {error && <p className="error">{error}</p>}
      <div className="flex flex-col items-center justify-center gap-2">
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Neues Passwort"
          className="rounded-lg border-[1px] border-gray-600 p-2"
        />
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Passwort bestätigen"
          className="rounded-lg border-[1px] border-gray-600 p-2"
        />
      </div>
      <button
        onClick={resetPassword}
        className="m-4 rounded-lg border-[1px] border-gray-600 px-2 py-1"
      >
        Passwort zurücksetzen
      </button>
    </div>
  );
}

export default ResetPasswordPage;
