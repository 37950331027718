import React, { FunctionComponent } from "react";

interface RevocationProps {
  // Definiere die Prop-Typen hier
}

const Revocation: FunctionComponent<RevocationProps> = () => {
  // Implementiere deine Komponente hier

  return (
    <div className="mx-auto my-[85px] flex w-full flex-col items-start justify-start gap-2 lg:w-1/3">
      <div className="font-GeistBold text-left text-3xl">
        <h1>Hinweise zur Wiederrufsbelehrung</h1>
      </div>
      <hr className="mb-4 h-[1px] w-full bg-slate-300" />
      <div className="relative flex items-start justify-start rounded-xl border-[2px] border-blue-900 bg-gray-50 px-6 py-4 shadow-lg">
        <span className="absolute left-0 top-0 h-full w-[12px] rounded-bl-xl rounded-tl-xl bg-blue-900"></span>
        <p className="text-base text-gray-800">
          Widerrufsrecht für Verbraucher <br />
          (Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu
          Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch
          ihrer selbstständigen beruflichen Tätigkeit zugerechnet werden kann.)
        </p>
      </div>
      <h1 className="m-0 p-0 text-3xl">Widerrufsbelehrung</h1>
      <p>
        <span className="text-lg text-gray-600">Widerrufsrecht</span>
        <br />
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
        diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage
        ab dem Tag,
        <br />
        - an dem Sie oder ein von Ihnen benannter Dritter, der nicht der
        Beförderer ist, die Waren in Besitz genommen haben bzw. hat, sofern Sie
        eine oder mehrere Waren im Rahmen einer einheitlichen Bestellung
        bestellt haben und diese einheitlich geliefert wird bzw. werden
        <br />
        - an dem Sie oder ein von Ihnen benannter Dritter, der nicht der
        Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat,
        sofern Sie mehrere Waren im Rahmen einer einheitlichen Bestellung
        bestellt haben und diese getrennt geliefert werden
        <br />
        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Juwelier Bucher GbR,
        Weinholdstraße 33, 08468 Reichenbach, Telefonnummer: 03765-12802,
        Telefaxnummer: 03765-719727, E-Mail-Adresse: info@juwelier-bucher.de)
        mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter
        Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
        widerrufen, informieren. Sie können dafür das beigefügte
        Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
        <br />
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung
        über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist
        absenden.
        <br />
        <br />
        <span className="text-lg text-gray-600">Folgen des Widerrufs</span>
        <br />
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
        wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
        Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
        andere Art der Lieferung als die von uns angebotene, günstigste
        Standardlieferung gewählt haben), unverzüglich und spätestens binnen
        vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
        Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese
        Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
        ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
        wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen
        wegen dieser Rückzahlung Entgelte berechnet.
        <br />
        Wir können die Rückzahlung verweigern, bis wir die Waren wieder
        zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie
        die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt
        ist.
        <br />
        Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen
        vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses
        Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die
        Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn
        Tagen absenden.
        <br />
        Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
        <br />
        Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn
        dieser Wertverlust auf einen zur Prüfung der Beschaffenheit,
        Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit
        ihnen zurückzuführen ist.
        <br />
        Ausschluss- bzw. Erlöschensgründe
        <br />
        <br />
        <span className="text-lg text-gray-600">
          Das Widerrufsrecht besteht nicht bei Verträgen
        </span>
        <br />
        <ul className="list-inside list-disc">
          <li>
            zur Lieferung von Waren, die nicht vorgefertigt sind und für deren
            Herstellung eine individuelle Auswahl oder Bestimmung durch den
            Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen
            Bedürfnisse des Verbrauchers zugeschnitten sind;
          </li>
          <li>
            zur Lieferung von Waren, die schnell verderben können oder deren
            Verfallsdatum schnell überschritten würde;
          </li>
          <li>
            zur Lieferung alkoholischer Getränke, deren Preis bei
            Vertragsschluss vereinbart wurde, die aber frühestens 30 Tage nach
            Vertragsschluss geliefert werden können und deren aktueller Wert von
            Schwankungen auf dem Markt abhängt, auf die der Unternehmer keinen
            Einfluss hat;
          </li>
          <li>
            zur Lieferung von Zeitungen, Zeitschriften oder Illustrierten mit
            Ausnahme von Abonnement-Verträgen.
          </li>
        </ul>
        <br />
        <span className="text-lg text-gray-600">
          Das Widerrufsrecht erlischt vorzeitig bei Verträgen
        </span>
        <br />
        <ul className="list-inside list-disc">
          <li>
            zur Lieferung versiegelter Waren, die aus Gründen des
            Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet
            sind, wenn ihre Versiegelung nach der Lieferung entfernt wurde;
          </li>
          <li>
            zur Lieferung von Waren, wenn diese nach der Lieferung aufgrund
            ihrer Beschaffenheit untrennbar mit anderen Gütern vermischt wurden;
          </li>
          <li>
            zur Lieferung von Ton- oder Videoaufnahmen oder Computersoftware in
            einer versiegelten Packung, wenn die Versiegelung nach der Lieferung
            entfernt wurde.
          </li>
        </ul>
      </p>
      <hr className="my-4 h-[1px] w-full bg-slate-300" />
      <span className="text-lg text-gray-600">Muster-Widerrufsformular</span>
      (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses
      Formular aus und senden Sie es zurück.)
      <br />
      <br />
      <div className="w-max border-[1px] border-gray-400 p-8">
        <span>
          An <br />
        </span>
        <p>
          Juwelier Bucher GbR <br />
          Weinholdstraße 33, 08468 Reichenbach <br />
          Telefaxnummer: 03765-719727 <br />
          E-Mail-Adresse: info@juwelier-bucher.de
        </p>
        <br />
        <br />
        <p>
          Hiermit widerrufe(n){" "}
          <span className="rounded-md bg-blue-100 p-1">ich/ wir (*)</span> den
          von <span className="rounded-md bg-blue-100 p-1">mir/ uns (*)</span>{" "}
          abgeschlossenen Vertrag über{" "}
          <span className="rounded-md bg-blue-100 p-1">
            den Kauf der folgenden Waren/ die Erbringung der folgenden
            Dienstleistung (*)
          </span>
          &nbsp;
          <span className="rounded-md bg-blue-100 p-1">
            Bestellt am/ erhalten am (*)
          </span>
        </p>
        <div className="mt-4 flex flex-col items-start justify-center gap-2">
          <div className="flex items-center justify-center gap-4">
            <p>Name des/ der Verbraucher(s):</p>
            <div className="h-[4ch] w-56 bg-gray-200"></div>
          </div>
          <div className="flex items-center justify-center gap-4">
            <p>Anschrift des/ der Verbraucher(s):</p>
            <div className="h-[4ch] w-56 bg-gray-200"></div>
          </div>
        </div>
        <div className="flex items-center justify-center gap-12">
          <div className="mt-44 flex w-3/4 flex-col items-start justify-start">
            <hr className="h-[1px] w-full bg-black" />
            <p>
              Unterschrift des/ der Verbraucher(s) (nur bei Mitteilung auf
              Papier)
            </p>
          </div>
          <div className="mt-44 flex w-1/4 flex-col items-start justify-start">
            <hr className="h-[1px] w-full bg-black" />
            <p>Datum</p>
          </div>
        </div>
        <br />
        <br />
        <span className="rounded-md bg-blue-100 p-1">
          (*) Unzutreffendes streichen.
        </span>
      </div>
    </div>
  );
};

export default Revocation;
